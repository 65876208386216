import { extendTheme } from "@chakra-ui/react";
import { buttonStyle } from "theme/button";
import { drawerStyle } from "theme/drawer";
import { linkStyle } from "theme/link";
import { textStyle } from "theme/text";

const config = {
  initialColorMode: "light",
  //  If true, your app will change color mode based on the user's system preferences.
  useSystemColorMode: false,
};

const theme = extendTheme({
  config,
  styles: {
    global: {
      // styles for the `body`

      body: {
        minHeight: "-webkit-fill-available",
        height: "100vh",
        color: "var(--gray-color)",
      },
    },
  },
  breakpoints: {
    base: "0px",
    xs: "320px",
    sm: "375px",
    "2sm": "475px",
    "3sm": "700px",
    md: "850px",
    lg: "950px",
    "2lg": "1100px",
    "3lg": "1217px",
    xl: "1280px",
    "2xl": "1450px",
    "3xl": "1536px",
  },
  components: {
    Link: linkStyle,
    Drawer: drawerStyle,
    Button: buttonStyle,
    Text: textStyle,
  },
});

export default theme;
