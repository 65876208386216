import {
  Avatar,
  Box,
  Flex,
  ListItem,
  Stack,
  StackDirection,
  Text,
  UnorderedList,
  VStack,
} from "@chakra-ui/react";
import ExperiencesCell from "molecules/ExperienceCell";

export interface Teams {
  name: string;
  src: string;
  position: string;
  description: string[];
  experiences?: {
    [key: string]: {
      title: string;
      descriptions: string[];
      note?: string;
      sub?: string;
    };
  };
  unorderedList?: string[];
}

const ProfileDetail = ({
  src,
  name,
  description,
  unorderedList,
  position,
  index = 0,
  className,
  experiences,
}: {
  flexDirection?: StackDirection;
  index?: number;
  className?: string;
} & Teams) => {
  return (
    <Stack
      w={"100%"}
      h="100%"
      bg="white"
      color="var(--blue-color)"
      position="relative"
      alignItems="flex-start"
      direction={{
        base: "column",
        lg: index % 2 === 0 ? "row" : "row-reverse",
      }}
      transition=".5s ease-in-out"
      p="3rem"
      minH="40rem"
      // boxShadow={"0 20px 70px -10px rgba(8,40,85,.4)"}
    >
      <Stack
        flex={1}
        h="100%"
        overflow={"hidden"}
        direction={index % 2 === 0 ? "row-reverse" : "row"}
        justifyContent="flex-end"
        position={"relative"}
      >
        <Avatar
          h={{
            base: "20rem",
            md: "20rem",
            "2lg": "25rem",
            xl: "30rem",
            "2xl": "35rem",
          }}
          w={{
            base: "20rem",
            md: "20rem",
            "2lg": "25rem",
            xl: "30rem",
            "2xl": "35rem",
          }}
          maxW="35rem"
          maxH="35rem"
          src={src}
          alignSelf="flex-start"
          border="5px solid var(--orange-color)"
          className={className}
        />
      </Stack>
      <Flex
        w={{ base: "100%", lg: "70%" }}
        flexDirection={"column"}
        py="2rem"
        px={{ md: "2rem", base: "0" }}
      >
        <VStack
          w="100%"
          alignItems={"flex-start"}
          borderLeft="1rem solid var(--orange-color)"
          pl="1rem"
          bg="rgb(237,242,247)"
        >
          <Text variant="title" color="var(--blue-color)" fontWeight={"bold"}>
            {name}
          </Text>

          <Text
            variant={"description"}
            lineHeight={"2.2rem"}
            color="var(--orange-color)"
            fontStyle={"italic"}
          >
            {position}
          </Text>
        </VStack>

        <Text
          as="h3"
          _before={{
            content: "''",
            position: "absolute",
            left: "0",
            bottom: "0",
            height: "3px",
            width: "150px",
            backgroundColor: "rgb(246, 142, 19) !important",
          }}
          fontSize="1.9rem"
          fontWeight={"bold"}
          pb="0.2rem"
          mt="2rem"
          position={"relative"}
          color="var(--primary-text-color)"
        >
          Professional career
        </Text>

        <VStack
          w="100%"
          mt="2rem"
          fontSize={"1.6rem"}
          spacing="2rem"
          alignItems="flex-start"
          color="black"
        >
          <UnorderedList
            fontSize={"1.6rem"}
            ml="3rem"
            color="black"
            spacing={"1.5rem"}
            mt="1rem"
          >
            {description?.map((item, index) => (
              <ListItem lineHeight={"2.2rem"} key={String(index) + "item"}>
                <Text
                  variant={"description"}
                  lineHeight={"2.2rem"}
                  textAlign="justify"
                  key={item}
                >
                  {" "}
                  {item}
                </Text>
              </ListItem>
            ))}
          </UnorderedList>
        </VStack>

        {unorderedList && (
          <UnorderedList
            fontSize={"1.6rem"}
            ml="5rem"
            color="black"
            spacing={"1.5rem"}
            mt="2rem"
          >
            {unorderedList.map((item, index) => (
              <ListItem lineHeight={"2.2rem"} key={String(index) + "item"}>
                <Text
                  variant={"description"}
                  fontStyle="italic"
                  textAlign="justify"
                >
                  {" "}
                  {item}
                </Text>
              </ListItem>
            ))}
          </UnorderedList>
        )}

        <Text
          as="h3"
          _before={{
            content: "''",
            position: "absolute",
            left: "0",
            bottom: "0",
            height: "3px",
            width: "90px",
            backgroundColor: "rgb(246, 142, 19) !important",
          }}
          fontSize="1.9rem"
          fontWeight={"bold"}
          pb="0.2rem"
          mt="2rem"
          position={"relative"}
          color="var(--primary-text-color)"
        >
          Experiences
        </Text>

        {experiences && Object.keys(experiences).length > 0 ? (
          <>
            {Object.keys(experiences).map((el, index) => {
              const item = experiences[el];
              const des = experiences[el].descriptions;
              return (
                <ExperiencesCell
                  key={el}
                  title={item.title}
                  content={
                    <Box>
                      {item.sub && (
                        <Text variant={"description"} color="black">
                          {item.sub}
                        </Text>
                      )}
                      <VStack
                        w="100%"
                        justifyContent={"flex-start"}
                        alignItems={"flex-start"}
                      >
                        {des && (
                          <UnorderedList
                            fontSize={"1.6rem"}
                            ml="3rem"
                            color="black"
                            spacing={"1.5rem"}
                            mt="1rem"
                          >
                            {des.map((item, index) => (
                              <ListItem
                                lineHeight={"2.2rem"}
                                key={String(index) + "item"}
                              >
                                <Text
                                  variant={"description"}
                                  fontStyle="italic"
                                  textAlign="justify"
                                >
                                  {" "}
                                  {item}
                                </Text>
                              </ListItem>
                            ))}
                          </UnorderedList>
                        )}
                      </VStack>
                      {item.note && (
                        <Text variant={"description"} py="1rem">
                          {item.note}
                        </Text>
                      )}
                    </Box>
                  }
                  ml="2rem"
                  mt="1rem"
                />
              );
            })}
          </>
        ) : (
          <></>
        )}
      </Flex>
    </Stack>
  );
};

export default ProfileDetail;
