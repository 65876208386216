import { Box, useMediaQuery } from "@chakra-ui/react";
import ProjectCell from "components/project/ProjectCell";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const Project = ({
  products,
}: {
  products: {
    subject: string;
    category: string;
    title: string;
    status: string;
    location: string;
    src: string;
  }[];
}) => {
  const [isLarger] = useMediaQuery("(min-width: 1300px)");
  const [isSmaller] = useMediaQuery("(max-width: 700px)");
  return (
    <Box margin="0 auto" w="100%" position={"relative"}>
      <Swiper
        slidesPerView={isSmaller ? 1 : isLarger ? 3 : 2}
        spaceBetween={0}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination, Autoplay, Navigation]}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        loop={true}
      >
        {products.map((el, index) => (
          <SwiperSlide key={index}>
            <ProjectCell {...el} />
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export default Project;
