import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Image,
  Link,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import MenuCollapse from "components/menu/MenuCollapse";
import React from "react";
import { MdOutlineMenu } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import { MenuListPage } from "utils/constants";

const MenuDrawer = ({
  onClickItem,
}: {
  onClickItem?: (id?: string) => () => void;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const location = useLocation();
  const btnRef = React.useRef(null);
  const navigate = useNavigate();

  const handleClickMenu = (id: string) => () => {
    onClose();
    setTimeout(() => {
      if (onClickItem) onClickItem(id)();
      else navigate(id);
    }, 300);

    // ;
  };
  return (
    <Box display={{ base: "flex", md: "none !important" }}>
      <Button
        fontWeight="bold"
        padding="0 1.5rem"
        fontSize="1.4rem"
        _hover={{
          color: "#f68e13",
        }}
        display="flex"
        bg="transparent"
        _active={{}}
        onClick={onOpen}
      >
        <MdOutlineMenu size={"2.5rem"} />
      </Button>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
        variant="base"
      >
        <DrawerOverlay />
        <DrawerContent minWidth={"30rem"}>
          <DrawerCloseButton />
          <DrawerHeader>
            <Image h="4.5rem" src="/img/hpLogo.png" marginLeft={"2rem"}></Image>
          </DrawerHeader>

          <DrawerBody>
            <VStack>
              {MenuListPage.map((el) => {
                if (el.items && el.items.length > 0)
                  return (
                    el.items &&
                    el.items.length > 0 && (
                      <MenuCollapse
                        title={el.title}
                        items={el.items}
                        onClickItem={(id) => () => {
                          handleClickMenu(`${el.url}${id}`)();
                        }}
                      />
                    )
                  );
                return (
                  <Link
                    key={el.title}
                    variant={"linkDrawer"}
                    href={el.url}
                    onClick={handleClickMenu(el.url)}
                    color={
                      location.pathname === (el.url ?? "temp")
                        ? "var( --highlight-color)"
                        : "#252525"
                    }
                  >
                    {el.title}
                  </Link>
                );
              })}
            </VStack>
            {/* <VStack alignItems={"flex-start"}>
              <Link variant={"linkDrawer"} href="#">
                Home
              </Link>

              <MenuCollapse
                title="Pages"
                items={[
                  { title: "About us" },
                  { title: "Services" },
                  { title: "Our Projects" },
                  { title: "Our Pricing" },
                  { title: "Portforlio" },
                  { title: "Gallery" },
                ]}
              />
              <Link variant={"linkDrawer"} href="#">
                Blog
              </Link>
              <MenuCollapse
                title="User"
                items={[{ title: "Login" }, { title: "Registration" }]}
              />
              <MenuCollapse
                title="Futures"
                listProps={{
                  divider: undefined,
                }}
                items={[
                  {
                    title: "Core Future",
                    items: [
                      { title: "Modern Design" },
                      { title: "Responsive Layout" },
                      { title: "Layout Builder" },
                    ],
                  },
                  {
                    title: "Layout Builder",
                    items: [
                      { title: "Advanced Layout Manager" },
                      { title: "Mega Menu Generator" },
                      { title: "Easy Drag & Drop" },
                    ],
                  },
                  {
                    title: "Easy Customization",
                    items: [
                      { title: "Advanced Typography Options" },
                      { title: "Custom 404 Page and Offline Page" },
                      { title: "Custom CSS/JS Code fields" },
                    ],
                  },
                  {
                    title: "Powerful Features",
                    items: [
                      { title: "Font Awesome" },
                      { title: "Bootstrap 4" },
                      { title: "Off-Canvas Menu" },
                    ],
                  },
                  {
                    title: "Latest Articles",
                    items: [
                      {
                        title:
                          "Peter Thiel would likely make $5 billion IRA withdrawal in 2022 under House bill",
                      },
                      {
                        title:
                          "SpaceX’s Inspiration4 crew speaks from orbit, with return slated for Saturday",
                      },
                    ],
                  },
                  {
                    title: "MegaMenu Options",
                    items: [
                      {
                        title: "Styling Menus",
                      },
                      {
                        title: "Menu Animation",
                      },
                      {
                        title: "Drag & Drop Menu Builder",
                      },
                      {
                        title: "Styling Options",
                      },
                    ],
                  },
                ]}
              />
              <Link variant={"linkDrawer"} href="#">
                Contact
              </Link>
            </VStack> */}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default MenuDrawer;
