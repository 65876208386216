import { Flex, StackProps, Text } from "@chakra-ui/react";
import React from "react";

const ExperiencesCell = ({
  title,
  content,
  ...rest
}: {
  title: string;
  content: React.ReactNode;
} & StackProps) => {
  return (
    <Flex flexDirection={"column"} w={"100%"} {...rest}>
      <Text
        variant={"description"}
        fontWeight={"bold"}
        position={"relative"}
        color="var(--primary-text-color)"
      >
        {title}
      </Text>
      <Flex
        flexDirection={"column"}
        alignItems="flex-start"
        fontSize="1.4rem"
        w="100%"
      >
        {content}
      </Flex>
    </Flex>
  );
};

export default ExperiencesCell;
