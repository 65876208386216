import { Box, HStack, Stack, Text, VStack } from "@chakra-ui/react";

const Intro = () => {
  return (
    <Box
      margin="0 auto"
      py="5rem"
      w="100%"
      px={{ base: "0rem", "2lg": "7rem" }}
      position={"relative"}
    >
      <Stack
        direction={{ md: "row", base: "column" }}
        w="100%"
        spacing={"1rem"}
        alignItems="center"
        justifyContent={"space-between"}
      >
        <VStack alignItems={"flex-start"} spacing="2rem" w={"100%"}>
          <Text
            fontSize={{ base: "3rem", md: "5rem" }}
            fontWeight="bold"
            color="var(--blue-color)"
            position={"relative"}
            textAlign={"center"}
            alignSelf={"center"}
            pb="3rem"
          >
            HP Advisory
            <br />
            Connecting investors and private capital to Vietnam
          </Text>
          <Box position={"relative"} w="100%" my="2rem">
            <Box
              w="100%"
              h="1rem"
              _before={{
                content: "''",
                display: "block",
                position: "absolute",
                left: 0,
                right: 0,
                bottom: 0,
                width: "100%",
                height: "4rem",
                backgroundImage: "url('/img/dots-bg-repeat.png')",
                backgroundRepeat: "repeat-x",
                backgroundSize: "contain",
              }}
            ></Box>
          </Box>
          <Text
            fontSize={{ base: "3rem", md: "5rem" }}
            lineHeight="6.5rem"
            fontWeight="bold"
            color="var(--blue-color)"
            position={"relative"}
            _before={{
              content: "''",
              position: "absolute",
              left: "0",
              bottom: "0",
              top: "calc(100% + 10px)",
              height: "0.5rem",
              width: "80%",
              backgroundColor: "rgb(246, 142, 19) !important",
            }}
          >
            Executive summary
          </Text>
          <HStack w="100%" position="relative" zIndex={1}>
            <VStack
              borderRadius={"1.5rem"}
              alignItems={"flex-start"}
              spacing="2rem"
              mt="1rem"
              w="100%"
              py="3rem"
            >
              <Text
                variant={"title"}
                textAlign="justify"
                as="span"
                color="var(--blue-color)"
              >
                <Text as="span" color="var(--orange-color)">
                  {" "}
                  HP Advisory Pte. Ltd. (“HP Advisory”)
                </Text>{" "}
                is a financial advisory firm in Singapore led by{" "}
                <Text as="span" color="var(--orange-color)">
                  Mr. Paul Wan{" "}
                </Text>
                and{" "}
                <Text as="span" color="var(--orange-color)">
                  Mr. Nguyen Huy Hoang (King Nguyen)
                </Text>{" "}
                , with our mainly focus in Vietnam;
              </Text>
              <Text
                variant={"title"}
                textAlign="justify"
                as="span"
                color="var(--blue-color)"
              >
                We also owned and managing a local advisory firm in Vietnam -
                H&Q Advisory Company Limited (“H&Q Advisory”). H&Q Advisory was
                founded in November 2014 and is an Independent Member of Morison
                Global;
              </Text>
              <Text
                variant={"title"}
                textAlign="justify"
                as="span"
                color="var(--blue-color)"
              >
                Our teams in H&Q/HP Advisory are together to have a strong track
                record and experience in commitments to bring profitable
                investment transaction and execution to Vietnam.
              </Text>
            </VStack>
          </HStack>
        </VStack>
        {/* 
        TBD
        <Box
          bgImage={"url(/img/mask.png)"}
          bgRepeat="no-repeat"
          bgSize={"contain"}
          bgPosition="50% 50%"
          w={{ base: "100%", "2sm": "80%", xl: "70%" }}
          height={"100%"}
        >
          <Box position="relative" zIndex={1} w="100%">
            <Box display={"inline-block"} position="relative" w="100%">
              <Center>
                <Image src="/img/slider-home.png" className="mask-image" />
              </Center>
            </Box>
          </Box>
        </Box> */}
      </Stack>
    </Box>
  );
};

export default Intro;
