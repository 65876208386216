import {
  Box,
  Center,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from "@chakra-ui/react";
import Project from "components/project";
import { useMemo, useState } from "react";
import { allProjects, hpServices } from "utils/constants";

const OurServices = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const services = useMemo(() => {
    return hpServices.filter((el) => el.showProject);
  }, []);

  return (
    <Box px={{ base: "0rem", "2lg": "7rem" }} pb="4rem" w={"100%"}>
      <Box position={"relative"} w="100%" my="2rem">
        <Box
          w="100%"
          h="1rem"
          _before={{
            content: "''",
            display: "block",
            position: "absolute",
            left: 0,
            right: 0,
            bottom: 0,
            width: "100%",
            height: "4rem",
            backgroundImage: "url('/img/dots-bg-repeat.png')",
            backgroundRepeat: "repeat-x",
            backgroundSize: "contain",
          }}
        ></Box>
      </Box>
      <VStack
        spacing={"1rem"}
        justifyContent="left"
        alignItems={"left"}
        mb="4rem"
      >
        <Box w={{ base: "100%", md: "70%" }}>
          <Text
            variant={"header"}
            color="#122c6c"
            position={"relative"}
            _before={{
              content: "''",
              position: "absolute",
              left: "0",
              bottom: "0",
              top: "calc(100% + 10px)",
              height: "0.5rem",
              width: "50%",
              backgroundColor: "rgb(246, 142, 19) !important",
            }}
          >
            HP Advisory - Our Sector Coverage
          </Text>
        </Box>

        <Text mt="2rem" variant={"description"} color="var(--blue-color)">
          Our Industry Coverage model is structured towards harnessing in-depth
          sector knowledge. The teams are aligned to specific sectors with a
          focus on providing specialist expertise that benefit clients.
        </Text>
      </VStack>

      <Tabs
        isFitted
        variant="unstyled"
        mt="4rem"
        tabIndex={tabIndex}
        onChange={setTabIndex}
      >
        <TabList
          overflowY="hidden"
          sx={{
            scrollbarWidth: "none",
            "::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {services.map((item, index) => (
            <Tab
              borderBottom={
                tabIndex === index
                  ? "5px solid var(--orange-color)"
                  : "5px solid"
              }
            >
              <Text
                variant={"title"}
                fontWeight={"bold"}
                whiteSpace={{ base: "nowrap", md: "pre-line" }}
                minW={"7rem"}
                color={
                  tabIndex === index
                    ? "var(--orange-color)"
                    : "var(--blue-color)"
                }
              >
                {item.title}
              </Text>
            </Tab>
          ))}
        </TabList>
        {/* <TabIndicator
          height="4px"
          bg="var(--orange-color)"
          borderRadius="1px"
        /> */}
        <TabPanels>
          {services.map((item, index) => (
            <TabPanel key={String(index)} px="0">
              <VStack py="1rem" alignItems={"flex-start"} spacing="1.5rem">
                {allProjects[item.key].length > 0 && (
                  <>
                    <Project products={allProjects[item.key] ?? []} />
                  </>
                )}
                {allProjects[item.key].length <= 0 && (
                  <Center minH="25rem" w="100%">
                    <Text variant={"description"} color="var(--blue-color)">
                      Updating...
                    </Text>
                  </Center>
                )}
              </VStack>
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
      {/* <Tabs
        isFitted
        variant="unstyled"
        tabIndex={tabIndex2}
        onChange={setTabIndex2}
        bg="rgba(255,255,255,0.6)"
      >
        <TabList>
          {section2.map((item, index) => (
            <Tab>
              <Text
                variant={"title"}
                fontWeight={"bold"}
                color={
                  tabIndex2 === index
                    ? "var(--orange-color)"
                    : "var(--blue-color)"
                }
              >
                {item.title}
              </Text>
            </Tab>
          ))}
        </TabList>
        <TabIndicator
          height="4px"
          bg="var(--orange-color)"
          borderRadius="1px"
        />
        <TabPanels>
          {section2.map((item, index) => (
            <TabPanel key={String(index)} borderTop={"4px solid"}>
              <VStack py="1rem" alignItems={"flex-start"} spacing="1.5rem">
                {allProjects[item.key]?.length > 0 && (
                  <>
                    <Project products={allProjects[item.key] ?? []} />
                  </>
                )}

                {allProjects[item.key]?.length <= 0 && (
                  <Center minH="25rem" w="100%">
                    <Text variant={"description"} color="var(--blue-color)">
                      Updating...
                    </Text>
                  </Center>
                )}
              </VStack>
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs> */}
    </Box>
  );
};

export default OurServices;
