import "assets/styles/index.scss";
import LayoutContainer from "components/layout/LayoutContainer";
import Home from "pages/home";
import Services from "pages/services";
import OurTeams from "pages/teams";
import { Route, Routes } from "react-router-dom";
import { routes } from "utils/routes";

function App() {
  return (
    <Routes>
      <Route path="/" element={<LayoutContainer />}>
        <Route index element={<Home />} />
        <Route path={`${routes.ServiceDetail}/:id`} element={<Services />} />
        <Route path={`${routes.AboutTeam}`} element={<OurTeams />} />
      </Route>
    </Routes>
  );
}

export default App;
