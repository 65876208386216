import { ArrowForwardIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  Flex,
  Image,
  List,
  ListItem,
  Stack,
  Text,
  UnorderedList,
  VStack,
} from "@chakra-ui/react";

const CoreCardServiceItem = ({
  src,
  title,
  descriptions,
  unorderedList,
  headerColor,
}: {
  title: string;
  src: string;
  descriptions: string[];
  unorderedList?: string[];
  headerColor?: string;
}) => {
  return (
    <Stack
      w={"100%"}
      h="100%"
      minH="25rem"
      bg="white"
      boxShadow="0 0 15px rgba(8,40,85,.4)"
      color="var(--blue-color)"
      _hover={{
        bg: "var(--blue-color)",
        color: "rgba(255,255,255,0.8)",
        transition: ".2s ease-in-out",
        ".service-img": {
          transform: "scale(1.2) !important",
        },
        ".read-more": {
          color: "var(--orange-color)",
        },
        ".service-info": {
          color: "white",
        },
      }}
      alignItems="flex-start"
      direction="column"
    >
      <Box
        w={"100%"}
        maxH={{ base: "23rem", xl: "30rem" }}
        minH={{ base: "23rem", xl: "30rem" }}
        h="100%"
        overflow={"hidden"}
        position="relative"
      >
        <Image
          className="service-img"
          transform="scale(1)"
          transition=".5s ease-in-out"
          src={src}
          w="100%"
          h="100%"
        />
        <Center
          position={"absolute"}
          left={0}
          right={0}
          bottom={0}
          background={"rgba(255,255,255,0.7)"}
          h="11.5rem"
        >
          <Text
            variant="title"
            color="var(--blue-color)"
            fontWeight={"bold"}
            className="service-title"
            textAlign={"center"}
            py="2rem"
          >
            {title}
          </Text>
        </Center>
      </Box>

      <Flex
        flexDirection={"column"}
        w="100%"
        h="100%"
        padding="2rem"
        flex={1}
        position={"relative"}
      >
        <VStack alignItems={"flex-start"} spacing={"0rem"} pb="1rem">
          <List spacing={3}>
            {descriptions.map((el, index) => (
              <ListItem
                key={String(index) + "item"}
                color="var(--blue-color)"
                className="service-info"
                textAlign="left"
                fontSize={{
                  base: "1.6rem",
                  md: "2rem",
                }}
              >
                {el}
              </ListItem>
            ))}
          </List>
        </VStack>
        {unorderedList && unorderedList.length > 0 && (
          <UnorderedList
            fontSize={"1.6rem"}
            ml="4rem"
            color="var(--gray-text)"
            spacing={"1rem"}
            className="service-info"
          >
            {unorderedList.map((item, index) => (
              <ListItem lineHeight={"2.2rem"} key={String(index) + "item"}>
                <Text variant={"sub"} fontStyle="italic" textAlign="left">
                  {" "}
                  {item}
                </Text>
              </ListItem>
            ))}
          </UnorderedList>
        )}

        <Flex flex={1} justifyContent={"flex-end"} alignItems={"flex-end"}>
          <Button
            rightIcon={<ArrowForwardIcon />}
            variant="outline"
            borderRadius={0}
            h="5.5rem"
            w="15rem"
            borderColor={"var(--blue-color)"}
            color="var(--blue-color)"
            fontSize={"1.6rem"}
            fontWeight="medium"
            bg="white"
            fontStyle={"italic"}
          >
            Read more...
          </Button>
        </Flex>
      </Flex>
    </Stack>
  );
};

export default CoreCardServiceItem;
