import { Box, Flex, Text, VStack } from "@chakra-ui/react";

const Footer = () => {
  return (
    <Flex
      w={"100%"}
      position={"relative"}
      margin="0 auto"
      justifyContent={"center"}
      flexDirection="column"
      alignItems={"center"}
    >
      <Box
        w="100%"
        position={"relative"}
        h="50rem"
        bgImage={"/img/hcm.jpg"}
        backgroundSize={"cover"}
        backgroundPosition={"bottom"}
      >
        <VStack
          position={"absolute"}
          bottom="0"
          left={"0"}
          right={"0"}
          top={{
            base: "70%",
            "2sm": "80%",
            md: "70%",
            "3lg": "82%",
          }}
          padding={"1rem"}
          background={"rgba(255,255,255,0.6)"}
          px="3rem"
        >
          <Text
            variant={"header"}
            color={"var(--blue-color)"}
            flex={1}
            textAlign={"center"}
          >
            HP Advisory - Connecting investors and private capital to Vietnam
          </Text>

          <VStack
            w="100%"
            my="2rem"
            py="2rem"
            alignItems={"flex-start"}
            color="var(--blue-color)"
          >
            <Text
              alignSelf={"flex-start"}
              variant={"sub"}
              color="var(--blue-color)"
              fontStyle={"italic"}
            >
              For further inquiries, please contact:
            </Text>

            <VStack borderLeft="0.5rem solid var(--orange-color)" pl="1rem">
              <Text
                variant="title"
                color="var(--blue-color)"
                fontWeight={"bold"}
                alignSelf={"flex-start"}
              >
                Mr. King Nguyen
              </Text>

              <Text
                variant={"sub"}
                lineHeight={"2.2rem"}
                color="var(--blue-color)"
                fontStyle={"italic"}
                alignSelf={"flex-start"}
              >
                Founder & CEO
              </Text>
              <Text
                variant={"sub"}
                lineHeight={"2.2rem"}
                color="var(--blue-color)"
                fontStyle={"italic"}
              >
                Email: king@hp-advisory.com
              </Text>
            </VStack>
          </VStack>
        </VStack>
      </Box>
    </Flex>
  );
};

export default Footer;
