import {
  Box,
  Collapse,
  Flex,
  Grid,
  GridItem,
  Image,
  Link,
  Text,
  VStack,
} from "@chakra-ui/react";
import ProfileCard from "components/teams/ProfileCard";
import ProfileDetail, { Teams } from "components/teams/ProfileDetail";
import { useMemo, useRef, useState } from "react";
import { hqAdvisoryUrl } from "utils/constants";

const OurTeams = () => {
  const [userIndex, setUserIndex] = useState<number>();
  const ref = useRef<HTMLDivElement>(null);

  const teams: Teams[] = [
    {
      name: "Mr. Paul Wan",
      src: "/img/profile/Paul.jpg",
      position: "Founder & Chairman",
      description: [
        "Mr. Paul Wan is a Chartered Accountant of England & Wales. He is the Founder and Managing Partner of Paul Wan & Co Group which consists it’s flagship audit company which was established 38 years ago,  consulting and advisory firms;",
        "Mr. Paul Wan was formerly from KPMG and he has been for the last 15 years. He has been specialising in IPO, M&A, capital fund raising projects both locally in Singapore and overseas;",
        "Mr. Paul Wan’s profile is very prominent and is well connected with investment banks and investors regarding to almost industries, the hospitality and construction industries in particular;",
        "Mr. Paul Wan is the first Asian to be the Global Chairman of Morison Global - an international accounting network.",
      ],
      experiences: {
        ex1: {
          title: "He had listed:",
          descriptions: [
            "11 companies in Singapore total;",
            "1 company in Taiwan;",
            "1 company in Malaysia;",
          ],
          note: "The above IPO with total capitalisation on approximately US$1.5 billions.",
        },
        ex2: {
          title: "Capital fund raising projects for:",
          descriptions: [
            "Joint venture for a Japanese medical group and a Singapore medical platform: US$80 millions;",
            "Media group in Singapore: US $150 millions;",
            "Hospital in Saudi Arabia: US$320 millions;",
            "Hotel development in Thailand: US$210 millions.",
          ],
        },
        ex3: {
          title: "Advisory: ",
          descriptions: [
            "Has acted as the Advisor for the sale of 3 hotels in Thailand and 1 hotel in UK.",
          ],
        },
        ex4: {
          title: "Currently: ",
          sub: "In progress listing 4 companies on NASDAQ:",
          descriptions: [
            "banking platform based in Hong Kong through a RTO;",
            "compliance software and platform from Singapore through a SPAC;",
            "Solar panel group from Australia through a SPAC.",
          ],
        },
      },
    },
    {
      name: "Mr. King Nguyen",
      src: "/img/profile/King.png",
      position: "Founder & CEO",
      description: [
        "Mr. King Nguyen is holding a CPA Vietnam and Master of International Accounting.",
        "Mr. King Nguyen:",
      ],
      unorderedList: [
        "is the Founder and Chairman of H&Q Advisory.",
        "has been the local liaison Partner of  Morison Global (formerly Morison International), an international accounting network, in Vietnam since 2009.",
      ],
      experiences: {
        ex1: {
          title: "",
          sub: "Mr. King Nguyen has over 22 years of experiences in the profession of strategic corporate finance and auditing. He derives great satisfaction from helping clients establish investment and  financial objectives. His selected job-done are as follows:",
          descriptions: [
            "Advisory on 5 real estate projects owned by a Biggest Fund Manager in Vietnam (period from 2015-2020).",
            "Assist a Hospitality Group of Netherlands in the sale of its assets in Vietnam to Minor International in 2013.",
          ],
        },
      },
    },
  ];

  const handleSelectUser = (index: number) => {
    setUserIndex(index !== userIndex ? index : undefined);
    setTimeout(() => {
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }, 100);
  };

  const info = useMemo(() => {
    if (undefined === userIndex) return;

    return teams[userIndex];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userIndex]);

  return (
    <Flex
      w={"100%"}
      minH="50rem"
      position={"relative"}
      margin="0 auto"
      justifyContent={"flex-start"}
      flexDirection="column"
      alignItems={"center"}
    >
      <VStack
        w={"100%"}
        px={{ base: "0", xl: "0", "2xl": "5rem" }}
        py="4rem"
        alignItems={"flex-start"}
      >
        <Box px={{ base: "2rem", md: 0 }}>
          <Text
            variant={"header"}
            fontWeight="bold"
            color="#122c6c"
            position={"relative"}
            _before={{
              content: "''",
              position: "absolute",
              left: "0",
              bottom: "0",
              top: "calc(100% + 10px)",
              height: "0.5rem",
              width: "80%",
              backgroundColor: "rgb(246, 142, 19) !important",
            }}
          >
            HP Advisory - Our Team
          </Text>
        </Box>
        <Grid
          alignSelf={"center"}
          my="5rem"
          templateColumns={{
            base: "repeat(1, 1fr)",
            md: "repeat(2, 1fr)",
          }}
          rowGap={20}
          columnGap={15}
        >
          {teams.map((item, index) => (
            <GridItem onClick={() => handleSelectUser(index)}>
              <ProfileCard {...item} isSelected={index === userIndex} />
            </GridItem>
          ))}
        </Grid>
        <Box ref={ref}>
          <Collapse in={info ? true : false} animateOpacity>
            {info && (
              <ProfileDetail
                {...info}
                index={0}
                flexDirection={"row"}
                className="round-profile"
              />
            )}
          </Collapse>
        </Box>
      </VStack>

      <VStack
        w={"100%"}
        px={{ base: "0", xl: "0", "2xl": "5rem" }}
        py="4rem"
        alignItems={"flex-start"}
        position={"relative"}
      >
        <Box
          w="100%"
          h="1rem"
          _before={{
            content: "''",
            display: "block",
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            width: "100%",
            height: "4rem",
            backgroundImage: "url('/img/dots-bg-repeat.png')",
            backgroundRepeat: "repeat-x",
            backgroundSize: "contain",
          }}
        ></Box>
        <Box px={{ base: "2rem", md: 0 }}>
          <Text
            variant={"header"}
            fontWeight="bold"
            color="#122c6c"
            position={"relative"}
            _before={{
              content: "''",
              position: "absolute",
              left: "0",
              bottom: "0",
              top: "100%",
              height: "0.5rem",
              width: "80%",
              backgroundColor: "rgb(246, 142, 19) !important",
            }}
          >
            Member site:
          </Text>
        </Box>
        <Flex justifyContent={"center"} mt="2rem" w="100%">
          <Link
            href={hqAdvisoryUrl}
            _hover={{
              textDecor: "none",
            }}
            target="_blank"
          >
            <Image
              h="8rem"
              src="/img/H_Q_Morison_Logo.png"
              marginLeft={"2rem"}
            ></Image>
          </Link>
        </Flex>
      </VStack>
    </Flex>
  );
};

export default OurTeams;
