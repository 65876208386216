import { Flex } from "@chakra-ui/react";
import CoreServices from "components/CoreService";
import Intro from "components/intro";
import OurServices from "components/our-services";

const Home = () => {
  return (
    <>
      <Intro />
      <Flex
        w={"100%"}
        minH="50rem"
        position={"relative"}
        margin="0 auto"
        justifyContent={"center"}
        flexDirection="column"
        alignItems={"center"}
      >
        <CoreServices />
        <OurServices />
        {/* <OurClients /> */}
      </Flex>
    </>
  );
};

export default Home;
