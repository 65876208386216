import { Box, Grid, GridItem, Stack, Text } from "@chakra-ui/react";
import CoreCardServiceItem from "components/CoreService/CoreCardServiceItem";
import { coreServices } from "utils/constants";

const CoreServices = () => {
  return (
    <Box px={{ base: "0rem", "2lg": "7rem" }} pb="4rem" w={"100%"}>
      <Box position={"relative"} w="100%">
        <Box
          w="100%"
          h="1rem"
          _before={{
            content: "''",
            display: "block",
            position: "absolute",
            left: 0,
            right: 0,
            bottom: 0,
            width: "100%",
            height: "4rem",
            backgroundImage: "url('/img/dots-bg-repeat.png')",
            backgroundRepeat: "repeat-x",
            backgroundSize: "contain",
          }}
        ></Box>
      </Box>
      <Stack
        direction={{ md: "row", base: "column" }}
        spacing={"1rem"}
        justifyContent="left"
        alignItems={"left"}
        mb="4rem"
      >
        <Box w={{ md: "47%", base: "100%" }}>
          <Text
            variant={"header"}
            color="#122c6c"
            position={"relative"}
            _before={{
              content: "''",
              position: "absolute",
              left: "0",
              bottom: "0",
              top: "calc(100% + 10px)",
              height: "0.5rem",
              width: "50%",
              backgroundColor: "rgb(246, 142, 19) !important",
            }}
          >
            HP Advisory - Our Core Services
          </Text>
        </Box>
      </Stack>

      <Grid
        templateColumns={{
          base: "repeat(1, 1fr)",
          md: "repeat(2, 1fr)",
          "2lg": "repeat(3, 1fr)",
        }}
        columnGap={{ base: 4, sm: 4, md: 10 }}
        rowGap={{ base: 10, md: 12 }}
      >
        {coreServices.map((el, index) => (
          <GridItem w="100%" cursor="pointer" key={String(index)}>
            <CoreCardServiceItem
              title={el.title}
              src={el.img}
              descriptions={el.descriptions}
              unorderedList={el.unorderedList}
            />
          </GridItem>
        ))}
      </Grid>
    </Box>
  );
};

export default CoreServices;
