import { routes } from "utils/routes";

export const MenuListPage: {
  title: string;
  url: string;
  items?: {
    title: string;
    url: string;
  }[];
}[] = [
  {
    title: "Home",
    url: routes.Home,
  },
  {
    title: "Our team",
    url: routes.AboutTeam,
  },
  // {
  //   title: "Our services",
  //   url: routes.OurServices,
  //   // TBD
  //   // url: routes.ServiceDetail,
  //   // items: [
  //   //   {
  //   //     title: "Corporate Finance",
  //   //     url: routes.CorporateFinance,
  //   //   },
  //   //   {
  //   //     title: "Financial Advisory Services",
  //   //     url: routes.FinancialAdvisoryServices,
  //   //   },
  //   //   {
  //   //     title: "Due Dilligence",
  //   //     url: routes.DueDilligence,
  //   //   },
  //   //   {
  //   //     title: "Taxation Services",
  //   //     url: routes.TaxationServices,
  //   //   },
  //   //   {
  //   //     title: "Accountancy Services",
  //   //     url: routes.AccountancyServices,
  //   //   },
  //   //   {
  //   //     title: "Payroll Services",
  //   //     url: routes.PayrollServices,
  //   //   },
  //   //   {
  //   //     title: "Business Start Up Services",
  //   //     url: routes.BusinessStartUpServices,
  //   //   },

  //   //   {
  //   //     title: "Risk Advisory Services",
  //   //     url: routes.RiskAdvisoryServices,
  //   //   },
  //   // ],
  // },
  // {
  //   title: "Our Partners ",
  //   url: routes.OurPartners,
  // },
  // {
  //   title: "News",
  //   url: routes.News,
  // },
  // {
  //   title: "Futures",
  //   items: [
  //     {
  //       title: "Core Future",
  //       items: [
  //         { title: "Modern Design" },
  //         { title: "Responsive Layout" },
  //         { title: "Layout Builder" },
  //       ],
  //     },
  //     {
  //       title: "Layout Builder",
  //       items: [
  //         { title: "Advanced Layout Manager" },
  //         { title: "Mega Menu Generator" },
  //         { title: "Easy Drag & Drop" },
  //       ],
  //     },
  //     {
  //       title: "Easy Customization",
  //       items: [
  //         { title: "Advanced Typography Options" },
  //         { title: "Custom 404 Page and Offline Page" },
  //         { title: "Custom CSS/JS Code fields" },
  //       ],
  //     },
  //     {
  //       title: "Powerful Features",
  //       items: [
  //         { title: "Font Awesome" },
  //         { title: "Bootstrap 4" },
  //         { title: "Off-Canvas Menu" },
  //       ],
  //     },
  //     {
  //       title: "Latest Articles",
  //       items: [
  //         {
  //           title:
  //             "Peter Thiel would likely make $5 billion IRA withdrawal in 2022 under House bill",
  //         },
  //         {
  //           title:
  //             "SpaceX’s Inspiration4 crew speaks from orbit, with return slated for Saturday",
  //         },
  //       ],
  //     },
  //     {
  //       title: "MegaMenu Options",
  //       items: [
  //         {
  //           title: "Styling Menus",
  //         },
  //         {
  //           title: "Menu Animation",
  //         },
  //         {
  //           title: "Drag & Drop Menu Builder",
  //         },
  //         {
  //           title: "Styling Options",
  //         },
  //       ],
  //     },
  //   ],
  // },
];

export const services = [
  {
    title: "Corporate Finance",
    img: "/img/services/CorporateFinance.jpeg",
    shortDescription:
      "Our core skills allow us to advise our clients on corporate restructuring and debt refinancing projects.",
    descriptions: [
      "Our core skills allow us to advise our clients on corporate restructuring and debt refinancing projects.",
      "We are well positioned globally to help you with your cross-border transactions to ensure that they execute smoothly, ensuring time critical milestones are met and therefore providing you and your investors with confidence.",
      "We can work on situations involving:",
    ],
    unorderedList: [
      "Refinancing of existing debt facilities",
      "Optimization of capital structure",
      "M&A solutions as part of corporate restructuring, including JV restructuring or dissolution and disposal of businesses and assets",
    ],
    id: routes.CorporateFinance,
  },
  {
    title: "Financial Advisory Services",
    img: "/img/services/FinancialAdvisoryServices.webp",
    shortDescription:
      "We offer consulting and financial outlook and forecasting. Encompassing feasibility studies and valuations; and advice on start-ups.",
    descriptions: [
      "We offer consulting and financial outlook and forecasting. Encompassing feasibility studies and valuations; and advice on start-ups.",
    ],
    unorderedList: [
      "Planning financial budget, implementing and monitoring the financial activities;",
      "Recurrently analyzing the financial status to verify any strength and weakness;",
      "Researching and raising the capital from investors and funds;",
      "Refinancing of existing debt facilities;",
      "Optimization of capital structure.",
    ],
    id: routes.FinancialAdvisoryServices,
  },
  {
    title: "Due Dilligence",
    img: "/img/services/DueDilligence.jpeg",
    shortDescription:
      "Financial and Tax due diligence help buyers, sellers and a range of financial institutions and private investors understand current and future tax structuring options, identifying potential risks, and comparing alternative deal structures that could meet each parties’ expectations.",
    descriptions: [
      "Financial and Tax due diligence help buyers, sellers and a range of financial institutions and private investors understand current and future tax structuring options, identifying potential risks, and comparing alternative deal structures that could meet each parties’ expectations.",
    ],
    unorderedList: [
      "Special comprehensive or limited review of financial and tax requirements of the Investor, in order to serve the process of investment, acquisition and merger of enterprises;",
      "Tax health check;",
      "Financial due diligence including analyzing internal control procedures for accounting and financial reporting systems to provide investors with a certain degree of confidence in the financial reports of the business.",
    ],
    id: routes.DueDilligence,
  },
  {
    title: "Taxation Services",
    img: "/img/services/TaxationServices.jpeg",
    shortDescription:
      "Having a responsive advisory like us will help your business with the delivery of practical, relevant and cost effective solutions.",
    descriptions: [
      "Having a responsive advisory like us will help your business with the delivery of practical, relevant and cost effective solutions.",
    ],
    unorderedList: [
      "Assist in preparations and documentations on transfer pricing reports required by the local authorities;",
      "Advice on the direct and indirect tax implications for current and proposed transactions;",
      "Value added tax, corporate income tax and personal income tax planning, computation and filing;",
      "Plan for tax minimization as well as tax effective business structuring;",
      "Preparation and lodging of submissions by industry;",
      "Liaison with and representations to tax authorities;",
      "Provision of tax and compliance requirements.",
    ],
    id: routes.TaxationServices,
  },
  {
    title: "Accountancy Services",
    img: "/img/services/AccountancyServices.jpeg",
    shortDescription:
      "Our team of experts are also qualified professionals to assist you to prepare complex financial reporting in accordance with VAS and IFRS.",
    descriptions: [
      "Our team of experts are also qualified professionals to assist you to prepare complex financial reporting in accordance with VAS and IFRS.",
      "We will ensure that you are compliant and that you fulfill your reporting and regulatory requirements, but we will do more. We can provide you with the insight to plan ahead and grow your business.",
    ],
    unorderedList: [
      "Design and implementation of accounting system to meet the Company’s management requirements, specific international requirements of overseas holding company and in accordance with Vietnamese Accounting System and Standards;",
      "Compilation of monthly/quarterly/annually financial statements;",
      "International and Vietnamese accounting advice and assistance.",
    ],
    id: routes.AccountancyServices,
  },
  {
    title: "Payroll Services",
    img: "/img/services/PayrollServices.jpeg",
    shortDescription:
      "Payroll calculation requires great concentration and a lot of time to ensure accuracy in all numbers. Our team of Professionals will take care of your people so that you can focus on growing your business.",
    descriptions: [
      "Payroll calculation requires great concentration and a lot of time to ensure accuracy in all numbers. Our team of Professionals will take care of your people so that you can focus on growing your business.",
    ],
    unorderedList: [
      "Prepare and submit initial declaration to the Social Insurance Department;",
      "Prepare and calculate monthly payroll for the Company’s staff;",
      "Prepare monthly social insurance and health insurance declaration and advise you on the obligation to pay to the Social Insurance Department;",
      "Other legal matters on the labor of the Company.",
    ],
    id: routes.PayrollServices,
  },
  {
    title: "Business Start Up Services",
    img: "/img/services/Business_StartUp_Services.jpeg",
    shortDescription:
      "Providing peace of mind when starting something new and ensuring your new venture runs smoothly from start to finish.",
    descriptions: [
      "Providing peace of mind when starting something new and ensuring your new venture runs smoothly from start to finish.",
    ],
    unorderedList: [
      "Assisting in deciding the framework through which you have to start-up your business.",
      "Assisting in registration of Limited Liability Companies / Joint Stock Companies.",
      "Assist in registration of Partnership firms.",
    ],
    id: routes.BusinessStartUpServices,
  },

  {
    title: "Risk Advisory Services",
    img: "/img/services/Risk_Advisory_Services.jpeg",
    shortDescription:
      "Helps companies improve process efficiency, fraud detection, internal control and regulatory compliance.",
    descriptions: [
      "Helps companies improve process efficiency, fraud detection, internal control and regulatory compliance.",
    ],
    unorderedList: [
      "Internal audit advisory services on identifying better management practices and implementing effective controls and compliance processes;",
      "Risk and internal control review services, including the review, design, implementation and maintenance of internal controls relevant to the business operations and the preparation of the financial statements to prevent fraud.",
    ],
    id: routes.RiskAdvisoryServices,
  },
];

export const hpServices = [
  {
    title: "Consumer and Retail",
    key: "consumerRetail",
    img: "/img/services/CorporateFinance.jpeg",
    shortDescription:
      "Our core skills allow us to advise our clients on corporate restructuring and debt refinancing projects.",
    descriptions: [
      "Our core skills allow us to advise our clients on corporate restructuring and debt refinancing projects.",
      "We are well positioned globally to help you with your cross-border transactions to ensure that they execute smoothly, ensuring time critical milestones are met and therefore providing you and your investors with confidence.",
      "We can work on situations involving:",
    ],
    unorderedList: [
      "Refinancing of existing debt facilities",
      "Optimization of capital structure",
      "M&A solutions as part of corporate restructuring, including JV restructuring or dissolution and disposal of businesses and assets",
    ],
    id: routes.CorporateFinance,
    showProject: true,
  },
  {
    title: "Health",
    key: "health",
    img: "/img/services/FinancialAdvisoryServices.webp",
    shortDescription:
      "We offer consulting and financial outlook and forecasting. Encompassing feasibility studies and valuations; and advice on start-ups.",
    descriptions: [
      "We offer consulting and financial outlook and forecasting. Encompassing feasibility studies and valuations; and advice on start-ups.",
    ],
    unorderedList: [
      "Planning financial budget, implementing and monitoring the financial activities;",
      "Recurrently analyzing the financial status to verify any strength and weakness;",
      "Researching and raising the capital from investors and funds;",
      "Refinancing of existing debt facilities;",
      "Optimization of capital structure.",
    ],
    id: routes.FinancialAdvisoryServices,
    showProject: true,
  },
  {
    title: "Industrials",
    key: "industrials",
    img: "/img/services/DueDilligence.jpeg",
    shortDescription:
      "Financial and Tax due diligence help buyers, sellers and a range of financial institutions and private investors understand current and future tax structuring options, identifying potential risks, and comparing alternative deal structures that could meet each parties’ expectations.",
    descriptions: [
      "Financial and Tax due diligence help buyers, sellers and a range of financial institutions and private investors understand current and future tax structuring options, identifying potential risks, and comparing alternative deal structures that could meet each parties’ expectations.",
    ],
    unorderedList: [
      "Special comprehensive or limited review of financial and tax requirements of the Investor, in order to serve the process of investment, acquisition and merger of enterprises;",
      "Tax health check;",
      "Financial due diligence including analyzing internal control procedures for accounting and financial reporting systems to provide investors with a certain degree of confidence in the financial reports of the business.",
    ],
    id: routes.DueDilligence,
  },
  {
    title: "Services",
    key: "services",
    img: "/img/services/TaxationServices.jpeg",
    shortDescription:
      "Having a responsive advisory like us will help your business with the delivery of practical, relevant and cost effective solutions.",
    descriptions: [
      "Having a responsive advisory like us will help your business with the delivery of practical, relevant and cost effective solutions.",
    ],
    unorderedList: [
      "Assist in preparations and documentations on transfer pricing reports required by the local authorities;",
      "Advice on the direct and indirect tax implications for current and proposed transactions;",
      "Value added tax, corporate income tax and personal income tax planning, computation and filing;",
      "Plan for tax minimization as well as tax effective business structuring;",
      "Preparation and lodging of submissions by industry;",
      "Liaison with and representations to tax authorities;",
      "Provision of tax and compliance requirements.",
    ],
    id: routes.TaxationServices,
  },
  {
    title: "Real Estate",
    key: "realEstate",
    img: "/img/services/AccountancyServices.jpeg",
    shortDescription:
      "Our team of experts are also qualified professionals to assist you to prepare complex financial reporting in accordance with VAS and IFRS.",
    descriptions: [
      "Our team of experts are also qualified professionals to assist you to prepare complex financial reporting in accordance with VAS and IFRS.",
      "We will ensure that you are compliant and that you fulfill your reporting and regulatory requirements, but we will do more. We can provide you with the insight to plan ahead and grow your business.",
    ],
    unorderedList: [
      "Design and implementation of accounting system to meet the Company’s management requirements, specific international requirements of overseas holding company and in accordance with Vietnamese Accounting System and Standards;",
      "Compilation of monthly/quarterly/annually financial statements;",
      "International and Vietnamese accounting advice and assistance.",
    ],
    id: routes.AccountancyServices,
    showProject: true,
  },
  {
    title: "Logistics",
    key: "logistics",
    img: "/img/services/PayrollServices.jpeg",
    shortDescription:
      "Payroll calculation requires great concentration and a lot of time to ensure accuracy in all numbers. Our team of Professionals will take care of your people so that you can focus on growing your business.",
    descriptions: [
      "Payroll calculation requires great concentration and a lot of time to ensure accuracy in all numbers. Our team of Professionals will take care of your people so that you can focus on growing your business.",
    ],
    unorderedList: [
      "Prepare and submit initial declaration to the Social Insurance Department;",
      "Prepare and calculate monthly payroll for the Company’s staff;",
      "Prepare monthly social insurance and health insurance declaration and advise you on the obligation to pay to the Social Insurance Department;",
      "Other legal matters on the labor of the Company.",
    ],
    id: routes.PayrollServices,
  },
  {
    title: "Renewable Energy",
    key: "renewableEnergy",
    img: "/img/services/PayrollServices.jpeg",
    shortDescription: "",
    descriptions: [],
    unorderedList: [],
    id: routes.PayrollServices,
    showProject: true,
  },
  {
    title: "Technology",
    key: "technology",
    img: "/img/services/PayrollServices.jpeg",
    shortDescription: "",
    descriptions: [],
    unorderedList: [],
    id: routes.PayrollServices,
    showProject: true,
  },
];

export const completedProject = [
  {
    subject: "Entertainment",
    category: "Financial Advisor",
    title: "Private Placement of Equity",
    status: "(closed in September 2023)",
    location: "Vietnamese Family Office",
    src: "/img/services/CorporateFinance.jpeg",
  },
  {
    subject: "Entertainment",
    category: "Financial Advisor",
    title: "Private Placement of Equity",
    status: "(closed in July 2022)",
    location: "Vietnamese Family Office",
    src: "/img/07.jpeg",
  },
  {
    subject: "Fashion Retail",
    category: "Sellside Advisor",
    title: "Private Placement of Equity",
    status: "(closed in 2019 & 2020)",
    location: "Japanese Fund",
    src: "/img/services/DueDilligence.jpeg",
  },
  {
    subject: "Bakery",
    category: "Sellside Advisor",
    title: "Private Placement of Equity",
    status: "(closed in 2018)",
    location: "Singapore Fund",
    src: "/img/services/Business_StartUp_Services.jpeg",
  },
];

export const coreServices = [
  {
    title: "Private Financing",
    descriptions: [
      "We assist with the financing needs of companies at all stages of their development through leveraging our extensive network of financial and corporate relationships. We help companies raise equity and debt financing from the most appropriate sources, including financial institutions, strategic investors or financial sponsors.",
    ],
    headerColor: "#18c5a9",
    img: "/img/services/CorporateFinance.jpeg",
  },
  {
    title: "Mergers & Acquisitions",
    descriptions: [
      "Represent sell side/ buy side;",
      "Assist in preparation on all marketing deliverables, modelling and valuation, structuring deal;",
      "Identify prospective offshore investors and assist clients in evaluation for a suitable investor;",
      "Assist in the due diligence processes.",
    ],
    headerColor: "var(--orange-color)",
    img: "/img/services/Business_StartUp_Services.jpeg",
  },
  {
    title: "Corporate and Financial Restructuring",
    descriptions: [
      "We advise our clients on corporate restructuring and debt refinancing projects. We can work on situations involving:",
    ],
    unorderedList: [
      "Refinancing of existing debt facilities;",
      "Optimization of capital structure;",
      "M&A solutions as part of corporate restructuring, including JV restructuring or dissolution and disposal of businesses and assets",
    ],
    headerColor: "#ff4081",
    img: "/img/services/FinancialAdvisoryServices.webp",
  },
];

export const allProjects: {
  [key: string]: {
    subject: string;
    category: string;
    title: string;
    status: string;
    location: string;
    src: string;
    isCompleted?: boolean;
  }[];
} = {
  consumerRetail: [
    {
      subject: "Entertainment",
      category: "Financial Advisor",
      title: "Private Placement of Equity",
      status: "(closed in September 2023)",
      location: "Vietnamese Family Office",
      src: "/img/services/CorporateFinance.jpeg",
      isCompleted: true,
    },
    {
      subject: "Entertainment",
      category: "Financial Advisor",
      title: "Private Placement of Equity",
      status: "(closed in July 2022)",
      location: "Vietnamese Family Office",
      src: "/img/07.jpeg",
      isCompleted: true,
    },
    // {
    //   subject: "Fashion Retail",
    //   category: "Sellside Advisor",
    //   title: "Private Placement of Equity",
    //   status: "(closed in 2019 & 2020)",
    //   location: "Japanese Fund",
    //   src: "/img/services/DueDilligence.jpeg",
    //   isCompleted: true,
    // },
    // {
    //   subject: "Bakery",
    //   category: "Sellside Advisor",
    //   title: "Private Placement of Equity",
    //   status: "(closed in 2018)",
    //   location: "Singapore Fund",
    //   src: "/img/services/Business_StartUp_Services.jpeg",
    //   isCompleted: true,
    // },
    // {
    //   subject: "Fashion Retails",
    //   category: "Financial Advisor",
    //   title: "Private Placement of Equity",
    //   status: "",
    //   location: "",
    //   src: "/img/services/CorporateFinance.jpeg",
    // },
    {
      subject: "Hi-End Food Stores",
      category: "Financial Advisor",
      title: "Fund Raising",
      status: "",
      location: "",
      src: "/img/07.jpeg",
    },
    // {
    //   subject: "Education",
    //   category: "Financial Advisor",
    //   title: "Private Placement of Equity",
    //   status: "",
    //   location: "Vietnamese Family Office",
    //   src: "/img/services/DueDilligence.jpeg",
    // },
  ],
  health: [
    {
      subject: "Healthcare Services",
      category: "Financial Advisor",
      title: "Private Placement of Equity",
      status: "(closed in September 2023)",
      location: "Singapore Family Office",
      src: "/img/services/CorporateFinance.jpeg",

      isCompleted: true,
    },
  ],
  realEstate: [
    {
      subject: "Top 10 Residential Developer (listed)",
      category: "Financial Advisor",
      title: "Private Share Issuance/ Secondary Share Transfer",
      status: "",
      location: "",
      src: "/img/services/CorporateFinance.jpeg",
    },
    {
      subject: "Top 10 Residential Developer (unlisted)",
      category: "Financial Advisor",
      title: "Debt Financing",
      status: "",
      location: "",
      src: "/img/services/CorporateFinance.jpeg",
    },
    {
      subject: "Luxury Lifestyle Resort Developer",
      category: "Financial Advisor",
      title: "Private Placement of Equity/Fund Raising",
      status: "",
      location: "",
      src: "/img/services/CorporateFinance.jpeg",
    },
  ],
  renewableEnergy: [
    {
      subject: "Rooftop Solar",
      category: "Sellside Advisor",
      title: "Private Placement of Equity",
      status: "(closed in July 2022)",
      location: "Vietnamese Family Office",
      src: "/img/services/CorporateFinance.jpeg",
      isCompleted: true,
    },
  ],
  technology: [
    {
      subject: "Technology",
      category: "Financial Advisor",
      title: "Private Placement of Equity",
      status: "(closed in October 2023)",
      location: "Singapore Family Office",
      src: "/img/services/CorporateFinance.jpeg",
      isCompleted: true,
    },
    {
      subject: "AI Services",
      category: "Financial Advisor",
      title: "Private Placement of Equity",
      status: "(closed in May 2023)",
      location: "Venture capital",
      src: "/img/services/CorporateFinance.jpeg",
      isCompleted: true,
    },
    {
      subject: "Waste and Water Treatment",
      category: "Financial Advisor",
      title: "Private Placement of Equity/Fund Raising",
      status: "",
      location: "",
      src: "/img/services/CorporateFinance.jpeg",
      isCompleted: false,
    },
  ],
  industrials: [],
  services: [],
  logistics: [],
};

export const hqAdvisoryUrl = "https://www.hqco.com.vn/";
